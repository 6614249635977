import fork from '@haaretz/l-fork.macro';

import type { PurchaseOfferFragment } from '@haaretz/s-fragments/PurchaseOffer';

type subscriptionsType = Readonly<{ [key: number]: 'Haaretz' | 'הארץ' | 'TheMarker' | 'משולב' }>;

const subscriptions: subscriptionsType = {
  239: 'Haaretz',
  243: 'הארץ',
  273: 'TheMarker',
  274: 'משולב',
};

function generateSubscriptionText(
  periodCalc: NonNullable<PurchaseOfferFragment['periodCalc']>,
  productNumber: NonNullable<PurchaseOfferFragment['productNumber']>
) {
  return fork({
    default: `מינוי ${subscriptions[productNumber]} במסלול תשלום ${
      periodCalc === 1 ? 'חודשי' : 'שנתי'
    }`,
    hdc: `You've chosen the ${periodCalc === 1 ? 'monthly' : 'annual'} plan`,
  });
}

export default generateSubscriptionText;
