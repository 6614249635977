import type { SelectedOfferProps } from '@haaretz/s-data-structure-types';

type Keys = keyof SelectedOfferProps;

const keysMap: Record<Keys, true> = {
  periodCalc: true,
  prices: true,
  productNumber: true,
  promotionNumber: true,
  saleCode: true,
  thankYouMailTemplate: true,
  contentId: true,
  paymentType: true,
  offerType: true,
};

export default function isValidSelectedOffer<T extends SelectedOfferProps>(
  selectedOffer: T
): selectedOffer is Required<T> {
  /* istanbul ignore else */
  if (!(selectedOffer instanceof Object)) return false;

  for (const key of Object.keys(keysMap)) {
    /* istanbul ignore else */
    if (!(key in selectedOffer)) return false;
  }

  return true;
}
