interface gtagItem {
  /** The ID of the item. */
  id: string;
  /** The name of the item.*/
  name: string;
  /** Currency of the items associated with the event, in 3-letter ISO 4217 format. */
  currency?: string;
  /**
   * Item quantity.
   * If not set, quantity is set to 1.
   */
  quantity?: number;
  /** The monetary price of the item, in units of the specified currency parameter.*/
  price?: number;
  /** The coupon name/code associated with the item. */
  coupon?: string;
}

type gtagEventProps = {
  action: string;
  category: string;
  label: string;
  value: number;
};

interface gtagAddToCartEventProps {
  /** The items for the event. */
  items: gtagItem[];
  /**	Currency of the items associated with the event, in 3-letter ISO 4217 format. */
  currency: string;
  /** The monetary value of the event. */
  value: number;
}

interface gtagPurchaseEventProps {
  /** The items for the event. */
  items: gtagItem[];
  /** Currency of the items associated with the event, in 3-letter ISO 4217 format. */
  currency: string;
  /** The monetary value of the event. */
  value: number;
  transactionId: string;
  /** The coupon name/code associated with the item. */
  coupon?: string;
}

export const gtagEvent = ({ action, category, label, value }: gtagEventProps) => {
  window.gtag('event', action, {
    event_category: category,
    event_label: label,
    value,
  });
};

export const itemsMapForGtag = (items: gtagItem[]) => {
  return items.map(item => ({
    item_name: item.name,
    item_id: item.id,
    price: item.price,
    quantity: item.quantity,
    currency: item.currency,
    coupon: item.coupon,
  }));
};

export const gtagAddToCartEvent = ({ items, currency, value }: gtagAddToCartEventProps) => {
  window.gtag('event', 'add_to_cart', {
    currency,
    value,
    items: itemsMapForGtag(items),
  });
};

export const gtagPurchaseEvent = ({
  items,
  currency,
  value,
  coupon,
  transactionId,
}: gtagPurchaseEventProps) => {
  window.gtag('event', 'purchase', {
    coupon,
    currency,
    value,
    transaction_id: transactionId,
    items: itemsMapForGtag(items),
  });
};
